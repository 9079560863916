<template>
  <Lab4uTransition :withAppear="true">
    <div
      v-if="isVisible"
      class="ui-lab4u__loader"
      :class="additionalClasses"
    >
      <div>
        <LoaderSVG :size="loaderSize" />
      </div>
    </div>
  </Lab4uTransition>
</template>

<script setup lang="ts">
import { computed, nextTick, ref } from "vue";

import Lab4uTransition from "@/components/UI/Transitions/Lab4uTransition.vue";

import { LoaderUIProps } from "@/components/UI/Loader/types";
import LoaderSVG from "@/components/UI/Loader/LoaderSVG.vue";

const isVisible = ref(false);
const isDarkened = ref(true);
const loaderSize = ref<LoaderUIProps["size"]>("xl");
const noScroll = ref(true);
const noInteraction = ref(true);

// function to change state of loader
async function pingLoader(options?: LoaderUIProps) {
  isDarkened.value = options?.visible ? options?.isDarkened ?? true : false;
  loaderSize.value = options?.size ?? "xl";
  noScroll.value = options?.visible ? options?.noScroll ?? true : false;
  noInteraction.value = options?.visible
    ? options?.noInteraction ?? true
    : false;

  await nextTick();
  isVisible.value = options?.visible ?? false;
}

// set global function to access loader
Object.defineProperty(window, "lab4u__toggleLoader", {
  enumerable: true,
  configurable: true,
  writable: true,
  value: pingLoader,
});

const additionalClasses = computed(() => [
  isDarkened.value ? "darkened" : "",
  noScroll.value ? "no-scroll" : "",
  noInteraction.value ? "no-interaction" : "",
]);
</script>

<style scoped>
.ui-lab4u__loader {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  pointer-events: none;
}
.ui-lab4u__loader.darkened {
  background-color: rgba(3, 3, 3, 0.6);
}
.ui-lab4u__loader.no-interaction {
  pointer-events: auto;
}
.ui-lab4u__loader div {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 24px;
  flex-shrink: 0;
  flex-grow: 0;

  border-radius: 16px;

  background: var(--ui-lab4u-color__background-primary_default);
  box-shadow: 0px 8px 30px 0px rgba(48, 57, 58, 0.1);
}
</style>

<style>
body:has(.ui-lab4u__loader.no-scroll) {
  overflow: hidden;
}

#loader {
  z-index: 999999;

  position: fixed;

  width: 100vw;
  height: 100vh;

  pointer-events: none;
}
</style>
