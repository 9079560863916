import { createApp } from "vue";

import "@/assets/css/index.css";

import LoaderUI from "@/components/UI/Loader/LoaderUI.vue";
import RecommendationsWithCatalog from "@/components/Analyses/RecommendationsWithCatalog/AsyncWrapper.vue";

import IngosLanding from "@/partnerships/Ingos/components/IngosLanding/AsyncWrapper.vue";

import PersonalCertificates from "@/components/Personal/Certificates/AsyncWrapper.vue";

createApp(LoaderUI).mount("#loader");
createApp(PersonalCertificates).mount("#Certificates");
createApp(IngosLanding).mount("#PartnershipIngosLanding");
createApp(RecommendationsWithCatalog).mount("#MainRecommendations");
