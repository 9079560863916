<template>
  <PersonalCertificates />
</template>

<script setup lang="ts">
import { defineAsyncComponent } from "vue";

const PersonalCertificates = defineAsyncComponent(
  () => import("@/components/Personal/Certificates/PersonalCertificates.vue")
);
</script>

<style scoped></style>
