<template>
  <svg
    v-if="props.size === 'xs'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM2.4 8C2.4 11.0928 4.90721 13.6 8 13.6C11.0928 13.6 13.6 11.0928 13.6 8C13.6 4.90721 11.0928 2.4 8 2.4C4.90721 2.4 2.4 4.90721 2.4 8Z"
    />
    <path
      d="M4.09968 2.42977C3.71955 1.88688 3.84863 1.12938 4.44223 0.834648C5.20883 0.454014 6.03392 0.197694 6.88662 0.0778553C8.13681 -0.0978482 9.41084 0.0248273 10.6045 0.435852C11.7982 0.846876 12.8777 1.53458 13.7547 2.44273C14.6317 3.35089 15.2813 4.45371 15.6504 5.66103C16.0196 6.86835 16.0977 8.14588 15.8785 9.38919C15.6592 10.6325 15.1489 11.8063 14.3891 12.8145C13.6293 13.8228 12.6417 14.6369 11.507 15.1904C10.733 15.5678 9.90567 15.8167 9.05728 15.9298C8.40035 16.0174 7.86976 15.4616 7.88132 14.799C7.89289 14.1363 8.44522 13.6215 9.09517 13.4919C9.56459 13.3983 10.0217 13.2445 10.4549 13.0332C11.2492 12.6458 11.9405 12.0759 12.4724 11.3702C13.0042 10.6644 13.3615 9.84274 13.5149 8.97243C13.6684 8.10212 13.6137 7.20784 13.3553 6.36272C13.0969 5.5176 12.6422 4.74562 12.0283 4.10991C11.4144 3.47421 10.6588 2.99281 9.82318 2.7051C8.98759 2.41738 8.09577 2.33151 7.22063 2.4545C6.74336 2.52158 6.27844 2.64974 5.83692 2.83462C5.22562 3.09062 4.47981 2.97265 4.09968 2.42977Z"
    />
  </svg>

  <svg
    v-if="props.size === 'sm'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10Z"
    />
    <path
      d="M5.1246 3.03721C4.64944 2.3586 4.81079 1.41173 5.55278 1.04331C6.51103 0.567518 7.5424 0.247117 8.60827 0.0973191C10.171 -0.12231 11.7636 0.0310341 13.2557 0.544815C14.7478 1.0586 16.0972 1.91822 17.1934 3.05342C18.2896 4.18861 19.1017 5.56714 19.563 7.07628C20.0244 8.58543 20.1221 10.1824 19.8481 11.7365C19.574 13.2906 18.9361 14.7578 17.9864 16.0182C17.0366 17.2785 15.8021 18.2961 14.3837 18.9879C13.4163 19.4598 12.3821 19.7709 11.3216 19.9123C10.5004 20.0218 9.83719 19.327 9.85165 18.4987C9.86611 17.6704 10.5565 17.0268 11.369 16.8648C11.9557 16.7478 12.5271 16.5557 13.0686 16.2916C14.0615 15.8073 14.9256 15.0949 15.5904 14.2127C16.2553 13.3305 16.7018 12.3034 16.8937 11.2155C17.0855 10.1276 17.0171 9.0098 16.6941 7.9534C16.3712 6.897 15.8027 5.93203 15.0354 5.13739C14.268 4.34276 13.3235 3.74102 12.279 3.38137C11.2345 3.02172 10.1197 2.91438 9.02579 3.06812C8.4292 3.15197 7.84805 3.31217 7.29615 3.54328C6.53202 3.86327 5.59977 3.71581 5.1246 3.03721Z"
    />
  </svg>

  <svg
    v-if="props.size === 'md'"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM6 24C6 33.9411 14.0589 42 24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24Z"
      fill="#F2F2F7"
    />
    <path
      d="M11.9549 6.79781C11.0046 5.44059 11.3276 3.55117 12.7927 2.77746C15.2415 1.48428 17.9026 0.621076 20.6598 0.233566C24.4104 -0.293545 28.2325 0.0744819 31.8136 1.30756C35.3947 2.54063 38.6332 4.60374 41.2642 7.3282C43.8951 10.0527 45.844 13.3611 46.9513 16.9831C48.0587 20.605 48.2931 24.4377 47.6354 28.1676C46.9777 31.8975 45.4466 35.4188 43.1672 38.4436C40.8879 41.4684 37.925 43.9108 34.5209 45.5711C32.0183 46.7916 29.3295 47.5641 26.5762 47.8613C24.9289 48.0392 23.6046 46.6534 23.6335 44.9968C23.6624 43.3402 25.0375 42.0463 26.6759 41.8C28.4808 41.5286 30.2398 40.9835 31.8907 40.1783C34.4438 38.9331 36.6659 37.1013 38.3754 34.8327C40.0849 32.5641 41.2333 29.9231 41.7265 27.1257C42.2198 24.3282 42.044 21.4538 41.2135 18.7373C40.383 16.0208 38.9214 13.5395 36.9481 11.4962C34.9749 9.4528 32.5461 7.90547 29.8602 6.98067C27.1744 6.05586 24.3078 5.77984 21.4949 6.17517C19.676 6.43081 17.9129 6.96245 16.2648 7.74679C14.7687 8.45879 12.9052 8.15502 11.9549 6.79781Z"
      fill="#00AACC"
    />
  </svg>

  <svg
    v-if="props.size === 'lg'"
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28ZM7 28C7 39.598 16.402 49 28 49C39.598 49 49 39.598 49 28C49 16.402 39.598 7 28 7C16.402 7 7 16.402 7 28Z"
      fill="#F2F2F7"
    />
    <path
      d="M13.9474 7.93077C12.8387 6.34735 13.2155 4.14303 14.9248 3.24038C17.7817 1.73166 20.8863 0.724588 24.1032 0.272494C28.4788 -0.342469 32.938 0.0868955 37.1159 1.52548C41.2939 2.96407 45.072 5.37103 48.1415 8.54957C51.211 11.7281 53.4846 15.588 54.7765 19.8136C56.0684 24.0392 56.3419 28.5106 55.5746 32.8622C54.8073 37.2137 53.021 41.3219 50.3618 44.8508C47.7026 48.3797 44.2459 51.2292 40.2744 53.1662C37.3547 54.5903 34.2177 55.4914 31.0056 55.8382C29.0837 56.0457 27.5387 54.429 27.5724 52.4963C27.6061 50.5636 29.2104 49.054 31.1219 48.7667C33.2276 48.4501 35.2797 47.8141 37.2058 46.8747C40.1844 45.4219 42.7769 43.2848 44.7713 40.6381C46.7658 37.9914 48.1055 34.9103 48.681 31.6466C49.2564 28.3829 49.0513 25.0294 48.0824 21.8602C47.1135 18.691 45.4082 15.7961 43.1061 13.4122C40.804 11.0283 37.9704 9.22305 34.8369 8.14411C31.7035 7.06517 28.3591 6.74315 25.0774 7.20437C22.9553 7.50261 20.8983 8.12286 18.9756 9.03792C17.2302 9.86858 15.0561 9.51419 13.9474 7.93077Z"
      fill="#00AACC"
    />
  </svg>

  <svg
    v-if="props.size === 'xl'"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32ZM8 32C8 45.2548 18.7452 56 32 56C45.2548 56 56 45.2548 56 32C56 18.7452 45.2548 8 32 8C18.7452 8 8 18.7452 8 32Z"
      fill="#F2F2F7"
    />
    <path
      d="M15.9399 9.06374C14.6728 7.25412 15.1034 4.73489 17.0569 3.70329C20.322 1.97904 23.8701 0.828101 27.5465 0.311421C32.5473 -0.391393 37.6434 0.0993091 42.4182 1.74341C47.193 3.38751 51.5109 6.13832 55.0189 9.77094C58.5269 13.4036 61.1253 17.8148 62.6018 22.6441C64.0782 27.4734 64.3908 32.5835 63.5138 37.5567C62.6369 42.53 60.5955 47.225 57.5563 51.2581C54.5172 55.2911 50.5667 58.5477 46.0279 60.7614C42.6911 62.3889 39.106 63.4188 35.4349 63.8151C33.2385 64.0523 31.4728 62.2045 31.5113 59.9957C31.5499 57.7869 33.3833 56.0617 35.5679 55.7333C37.9744 55.3715 40.3197 54.6447 42.5209 53.5711C45.925 51.9108 48.8879 49.4684 51.1672 46.4436C53.4466 43.4188 54.9777 39.8975 55.6354 36.1676C56.2931 32.4377 56.0587 28.605 54.9513 24.9831C53.844 21.3611 51.8951 18.0527 49.2642 15.3282C46.6332 12.6037 43.3947 10.5406 39.8136 9.30756C36.2325 8.07448 32.4104 7.70646 28.6598 8.23357C26.2346 8.57441 23.8838 9.28327 21.6864 10.329C19.6917 11.2784 17.207 10.8734 15.9399 9.06374Z"
      fill="#00AACC"
    />
  </svg>
</template>

<script setup lang="ts">
import { LoaderUIProps } from "@/components/UI/Loader/types";

const props = withDefaults(defineProps<{ size: LoaderUIProps["size"] }>(), {
  size: "xl",
});
</script>

<style scoped>
svg {
  animation: rotate 2s linear infinite;
}

svg path:nth-child(1) {
  fill: var(--ui-lab4u-color__background-secondary_default);
}
svg path:nth-child(2) {
  fill: var(--ui-lab4u-color__brand_default);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
