<template>
  <Lab4uTransition :withAppear="true">
    <IngosLanding />
  </Lab4uTransition>
</template>

<script setup lang="ts">
import Lab4uTransition from "@/components/UI/Transitions/Lab4uTransition.vue";
import { defineAsyncComponent } from "vue";

const IngosLanding = defineAsyncComponent(
  () => import("@/partnerships/Ingos/components/IngosLanding/IngosLanding.vue")
);
</script>
