<template>
  <RecommendationsWithCatalog />
</template>

<script setup lang="ts">
import { defineAsyncComponent } from "vue";

const RecommendationsWithCatalog = defineAsyncComponent(() => {
  return import(
    "@/components/Analyses/RecommendationsWithCatalog/RecommendationsWithCatalog.vue"
  );
});
</script>

<style>
#MainRecommendations {
  margin: 40px auto 20px;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 14px;
  align-items: center;

  max-width: 1280px;
}
@media screen and (min-width: 981px) {
  #MainRecommendations {
    padding: 0 20px;
  }
}
</style>
